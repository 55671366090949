<template>
  <div class="footer--payment">
    <span v-for="{ name, size, img } in paymentsByStore" :key="name">
      <router-link :to="localizedRoute('/zahlungsarten')">
        <img
          v-if="img"
          v-lazy="img"
          :alt="name"
          :class="size"
          width="60"
          height="35"
        >
        <template v-if="!img">{{ name }}</template>
      </router-link>
      <!-- span v-if="name == 'KlarnaOnSiteMessaging'">
        <klarna-placement data-key="sidebar-promotion-auto-size" data-locale="de-DE" v-pre />
      </span -->
    </span>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'FooterTrustPaymentMethods',
  data () {
    return {
      paymentLogos: [
        { name: 'Visa', size: 'logo-size-md', img: '/assets/logo/logo-visa.svg' },
        { name: 'Mastercard', size: 'logo-size-sm', img: '/assets/logo/logo-mastercard.svg' },
        { name: 'PayPal', size: 'logo-size-lg', img: '/assets/logo/logo-paypal.svg' },
        { name: 'American Express', size: 'logo-size-lg', img: '/assets/logo/logo-americanexpress.svg' },
        { name: 'Klarna', size: 'logo-size-lg', img: '/assets/logo/logo-klarna.svg' },
        { name: 'Vorkasse' },
        { name: 'ApplePay', size: 'logo-size-apple-pay', img: '/assets/logo/applepay.svg' },
        { name: 'Ratenkauf', size: 'logo-size-lg', img: 'https://ratenkauf.easycredit.de/api/resource/webcomponents/v3/easycredit-components/assets/ratenkauf-logo.svg', excludeStores: ['zaun24_at'] },
        { name: 'Billie', size: 'logo-size-lg', img: '/assets/logo/logo-billie.svg', excludeStores: ['zaun24_at'] }
      ]
    }
  },
  computed: {
    paymentsByStore () {
      return this.paymentLogos.filter((payment) => {
        return typeof payment.excludeStores === 'undefined' ||
          !payment.excludeStores.includes(currentStoreView().storeCode)
      })
    }
  }
}
</script>
