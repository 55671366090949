<template>
  <div>
    <a
      href="/bester-online-shop.html"
      class="badge-ntv"
    >
      <img
        v-lazy="'/assets/logo/seal-ntv-2021.svg'"
        alt="Deutschlands beste Online-Shops Gesamtsieger"
        width="150px"
        height="150px"
      >
    </a>
    <a
      href="/bester-online-shop.html"
      class="badge-haendler"
    >
      <img
        v-lazy="'/assets/logo/beste-haendler-2021.svg'"
        alt="Beste deutsche Online-Händler"
        width="81"
        height="150px"
      >
    </a>
    <a
      href="/bester-online-shop.html"
      class="badge-trusted-shops"
    >
      <img
        v-lazy="'/assets/logo/ta-excellent-shop.svg'"
        alt="Trusted Shops"
        width="103px"
        height="150px"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'FooterTrustSealsBest'
}
</script>
